/* Estilos responsivos */
@media (max-width: 768px) {
  .card {
    width: calc(50% - 20px);
  }
  .carusel {
    max-width: 100%; /* Ancho máximo de las cards */
  }
}

@media (max-width: 480px) {
  .card {
    width: calc(100% - 20px);
  }
  .carusel {
    max-width: 100%; /* Ancho máximo de las cards */
  }
}



body { font-family: 'Roboto', sans-serif; } .container { padding: 1rem; } .card { transition: all 0.3s; } .card:hover { transform: translateY(-5px); } .pic { height: 200px; } .card-title { color: #333; } .card-description { color: #666; } .button { display: inline-block; padding: 0.5rem 1rem; margin-top: 0.5rem; text-decoration: none;} 

.carusel {
  display: block; /* Centra la imagen */
  margin: 0 auto; /* Centra la imagen horizontalmente */
  margin-top: 40px;
  border-radius: 10px; /* Bordes redondeados para la imagen */
  max-width: 98%; /* Ancho máximo de las cards */
  justify-content: center; /* Centra horizontalmente las cards */
  align-items: center; /* Centra verticalmente las cards */
}


.container {
  display: flex; /* Utilizamos flexbox para el alineamiento */
  flex-wrap: wrap; /* Permite que las cards se envuelvan en múltiples líneas */
  justify-content: center; /* Centra horizontalmente las cards */
  align-items: center; /* Centra verticalmente las cards */
}


.card {
  background-color: #f4f4f4; /* Color de fondo de la card */
  border-radius: 15px; /* Bordes redondeados */
  padding: 20px; /* Espaciado general */
  margin-bottom: 20px; /* Margen inferior */
  max-width: 300px; /* Ancho máximo de las cards */
  margin: 10px; /* Espacio entre las cards */
}

.pic {
  display: block; /* Centra la imagen */
  margin: 0 auto; /* Centra la imagen horizontalmente */
  border-radius: 10px; /* Bordes redondeados para la imagen */
  max-width: 180px; /* Ancho máximo de las cards */
  max-height: 220px;
}

.button {
  display: block; /* Hace que los botones ocupen todo el ancho disponible */
  width: 96%;
  background-color: #007bff; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  font-weight: bold; /* Texto en negrita */
  border: none; /* Sin borde */
  border-radius: 20px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno del botón */
  margin-top: 10px; /* Margen superior */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
}

.card button:hover {
  background-color: #0056b3; /* Color de fondo del botón al pasar el ratón por encima */
}


@media (max-width: 480px) {
  .card {
    width: calc(100% - 20px); /* 100% de ancho para cada card en pantallas pequeñas */
  }
}


@media (max-width: 768px) {
  .container {
    padding: 0.5rem; /* Reduce el relleno del contenedor */
  }

  .card {
    max-width: 100%; /* Establece el ancho máximo de las tarjetas al 100% */
    margin: 0.5rem auto; /* Añade un pequeño margen vertical y centra las tarjetas */
  }

  .pic {
    max-width: 100%; /* Establece el ancho máximo de las imágenes al 100% */
    height: auto; /* Permite que la altura de las imágenes se ajuste automáticamente */
  }

  .button {
    width: 94%; /* Establece el ancho de los botones al 100% */
    margin-top: 0.5rem; /* Añade un pequeño margen superior a los botones */
  }
}
