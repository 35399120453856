.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .footer-section {
    flex: 1;
    margin-bottom: 20px;
  }
  
  .social-links a {
    display: block;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;
  }
  
  .comment-box {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Estilos responsivos del footer */
@media (max-width: 768px) {
    footer {
      padding: 20px 10px; /* Ajusta el padding del footer para dispositivos móviles */
    }
  
    .social-links {
      text-align: center; /* Centra los enlaces sociales */
      margin-top: 10px; /* Ajusta el margen superior de los enlaces sociales */
    }
  
    .social-links a {
      margin: 0 8px; /* Ajusta el margen entre los enlaces sociales */
    }
  }
  