

/* Estilos generales */
body {
    font-family: 'Roboto', sans-serif;
  }
  
  /* Estilos del logo */
  .logo {
    width: 70px; /* Ajusta el tamaño del logo según sea necesario */
    height: auto; /* Altura automática para mantener la proporción de la imagen */
    border: 2px solid black; /* Borde negro de 2px */
    border-radius: 10px; /* Bordes redondeados */
    margin-left: auto; /* Fija el logo a la derecha */
  }
  
  /* Estilos para el header y el nav */
  .header {
    background-color: #333; /* Color de fondo del header */
    padding: 10px; /* Espaciado interno del header */
    display: flex; /* Utilizamos flexbox para alinear elementos */
    justify-content: space-around; /* Espacio entre los elementos */
    align-items: center; /* Alineamos los elementos verticalmente al centro */
  }
  
  .nav {
    display: flex; /* Utilizamos flexbox para alinear elementos */
    align-items: center; /* Alineamos los elementos verticalmente al centro */
  }
  
  /* Estilos para el dropdown */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background-color: #333;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px; /* Ajusta el tamaño del border-radius */
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content input[type="text"],
  .dropdown-content ul {
    padding: 10px;
    margin: 2px;
    list-style: none;
    border-radius: 5px; /* Bordes redondeados para el buscador */
  }
  
  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 10px;
    font-family: 'Arial', sans-serif; /* Cambia la fuente dentro del dropdown */
    border-radius: 5px; /* Bordes redondeados para los botones */
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Estilos del nombre de la empresa */
  .company-name {
    font-family: 'Roboto', 'Arial', sans-serif; /* Cambia la fuente */
    font-size: 20px; /* Ajusta el tamaño del texto */
    color: white; /* Color del texto */
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .header {
      flex-direction: column; /* Cambia la dirección de los elementos en dispositivos móviles */
      align-items: flex-start; /* Alinea los elementos a la izquierda en dispositivos móviles */
    }
  
    .logo {
      margin: 10px 0; /* Ajusta el margen superior e inferior del logo */
    }
  
    .company-name {
      text-align: center; /* Centra el nombre de la empresa */
      margin-bottom: 10px; /* Ajusta el margen inferior del nombre de la empresa */
    }
  }
  